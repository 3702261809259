<template>
    <div class="Evaluation">
      <!-- 测评 -->
      <div :class="isPc === 'true' ? 'main': 'phoneStyle main'">
          <div class="banner">
              <img src="../../../assets/images/service/gauge/banner.png" alt="">
          </div>
          <div class="content">
            <router-view></router-view>
          </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
        return {
            isPc: true,
        }
    },
    created() {
        this.isPc = sessionStorage.getItem('isPc');
        console.log(this.isPc)
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .Evaluation{
    // height: calc(100vh - 180PX);
    // height: 100%;
      
      .main{
        height: 100%;
          width: 46%;
          // box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);
          box-shadow: -2px -2px 4px 0px rgba(0,0,0,0.14), 2px 2px 4px 0px rgba(0,0,0,0.13);
          display: block;
          .banner{
              img{
                  width: 100%;
              }
          }
          .content{
            height: 100%;
              padding: 20px 125px;
              box-sizing: border-box;
          }
      }
      .phoneStyle.main{
        width: 90%;
        .content{
          padding: 20px;
        }
      }
  }
//   @media screen and (max-width: 830PX) {
//     .Evaluation{
//   .main{
//         width: 90%;
//     }
//   }
// }
  </style>